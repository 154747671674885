import HomeIcon from '@mui/icons-material/Home';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import StoreIcon from '@mui/icons-material/Store';
import RuleIcon from '@mui/icons-material/Rule';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LoginIcon from '@mui/icons-material/Login';
import { SwipeableDrawer } from "@mui/material";
import { Fragment, useState } from "react";
import logo from '../../assets/Images/logo.webp';
import SportsIcon from '@mui/icons-material/Sports';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../Store/Slices/User/userSlice';
import axios from 'axios';
import { Sponsers } from '../components/Sponsers';

const MainMenuList = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.account);
    const auth = useSelector(state => state.user.auth) || false;
    const token = useSelector(state => state.user.token);

    const logoStyle = {
        height: "50%",
        margin: '5% auto'
    }
    
    const logout = (event) => {
        event.preventDefault();
        toggleDrawer(false);
        axios({
            method: "delete",
            url: '/api/user/logout',
            headers: { 'Authorization': `Bearer ${token}` }
        }).then(() => {
            dispatch(userActions.clearAccount);
            dispatch(userActions.clearOrders);
            dispatch(userActions.clearPayments);
            dispatch(userActions.clearRegistrations);
            dispatch(userActions.clearToken);
            dispatch(userActions.logout);
            window.location.replace('/');
        }).catch((error) => {
            alert(error.message);
        });
    }
    
    const [state, setState] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [props.anchor]: open });
    };
    const replaceLocation = (link) => {
        toggleDrawer(false);
        navigate(link);
        
    }
    return <Fragment>
        <Button sx={props.sx} startIcon={<MenuIcon />} onClick={toggleDrawer(true)} variant="text" color='action'>{props.text}</Button>
        <SwipeableDrawer
            sx={{ zIndex: 1200 }}
            anchor={props.anchor}
            open={state[props.anchor]}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', overflowY: 'scroll' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {!auth ? <Link sx={{ display: 'flex' }} to="/">
                        <img style={logoStyle} src={logo} alt="Slo pitch for awarness logo. It has four different colored puzzle peiece and a bat with with the text SPFA across it." />
                    </Link> : <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }} >
                        <Avatar
                            alt={`${user.name.givenName} ${user.name.familyName}`}
                            src={`/api/images/200/${user.profileImage}`}
                            sx={{ width: '30%', height: 'auto', aspectRatio: '1/1', margin: '1rem auto' }}
                        />
                        <p style={{ margin: '0' }}>{`${user.name.givenName} ${user.name.familyName}`}</p>
                        <p>{user.username}</p>
                    </Box>}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', p: '1rem', justifyContent: 'center'}}>
                    <Link to='/' ><Button onClick={toggleDrawer(false)} startIcon={<HomeIcon />} variant='text' color='action'>Home</Button></Link>
                    <Link to='/tournaments'><Button onClick={toggleDrawer(false)} startIcon={<SportsBaseballIcon />} variant='text' color='action'>Tournaments</Button></Link>
                    <Link to='/tournament-of-champions'><Button onClick={toggleDrawer(false)} startIcon={<EmojiEventsIcon />} variant='text' color='action'>Tournament of Champions</Button></Link>
                    <Link to='/store'><Button onClick={toggleDrawer(false)} startIcon={<StoreIcon />} variant='text' color='action'>Store</Button></Link>
                    <Link to='/about-us'><Button onClick={toggleDrawer(false)} startIcon={<InsertEmoticonIcon />} variant='text' color='action'>About Us</Button></Link>
                    <Link to='/rules-info'><Button onClick={toggleDrawer(false)} startIcon={<RuleIcon />} variant='text' color='action'>Rules & Info</Button></Link>
                    <hr />
                    {auth && (user.permissions.admin || user.permissions.convener) && <Box>
                        <Button onClick={() => { replaceLocation(`/dashboard${!user.permissions.admin ? '/tournaments' : ""}`) }} startIcon={<SportsIcon />} variant='text' color='action'>Dashboard</Button>
                    </Box>}
                    {!auth ? <Box>
                        <Link to='/login'><Button onClick={toggleDrawer(false)} startIcon={<LoginIcon />} variant='text' color='action'>Login</Button></Link>
                    </Box> : <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }} >
                            <Link to={`/account`}><Button onClick={toggleDrawer(false)} startIcon={<AccountCircleIcon />} variant='text' color='action'>Account</Button></Link>
                        <Link onClick={logout} to={`/logout`}><Button startIcon={<PowerSettingsNewIcon />} variant='text' color='action'>Logout</Button></Link>
                    </Box>}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', p: '1rem', justifyContent: 'center' }}>
                    <Sponsers isMobile={true} />
                </Box>
            </Box>
        </SwipeableDrawer>
    </Fragment>
}
export default MainMenuList;