import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import GroupsIcon from '@mui/icons-material/Groups';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RuleIcon from '@mui/icons-material/Rule';
import QuizIcon from '@mui/icons-material/Quiz';

const DashboardPages = [
    {
        title: "Overview",
        link: '/dashboard',
        Icon: <RemoveRedEyeIcon />,
        key: 'status'
    },
    {
        title: "Tournaments",
        link: '/dashboard/tournaments',
        Icon: <SportsBaseballIcon />,
        key: 'tournaments'
    },
    {
        title: "Registrations",
        link: '/dashboard/registrations',
        Icon: <AppRegistrationIcon />,
        key: 'registrations'
    },
    {
        title: "Teams",
        link: '/dashboard/teams',
        Icon: <GroupsIcon />,
        key: 'teams'
    },
    {
        title: "Site Users",
        link: '/dashboard/users',
        Icon: <AccountCircleIcon />,
        key: 'users'
    },
    {
        title: "Rules and Info",
        link: '/dashboard/info',
        Icon: <RuleIcon />,
        key: 'rulesInfo'
    },
    {
        title: "Faq",
        link: '/dashboard/faq',
        Icon: <QuizIcon />,
        key: 'faq'
    }
];

export default DashboardPages;