import { Box } from "@mui/system";
import {
    Button,
    Grid,
    Typography,
} from "@mui/material";
import softball from './assets/Images/sadSoftball.png'
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();
    const handleClick = (event) => {
        if(!event.isTrusted) return;
        navigate('/')
    }
    return <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <img style={{ width: '250px' }} alt='Page not found sad looking yellow softball animation' src={softball} />
        <Typography variant='h1'>Whoops!</Typography>
        <Typography variant='h6'>Error 404</Typography>
        <Typography variant='h3'>Page not found.</Typography>
        <Button variant="contained" onClick={handleClick} sx={{ borderRadius: "25px", margin: { xs: "3% 2%", md: "1%" }, width: { xs: '96%', md: "45%"} }}>Go home</Button>
    </Box>
}
export default NotFound;