import { useState } from "react";
import { Box, Button, Checkbox, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import EventDate from "./EventDate";
import SpotsLeft from "./spotsLeft";
import RegisterButton from "./Registrations/RegisterButton";
import ImgNextGen from "../../../shared/NextGenImages";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSwipeable } from 'react-swipeable';
import AutismAwarenessBadge from "../AutismAwarenessBadge";

const TournamenListItem = ({
    tournament,
    isSearched = false,
    isAdmin = false,
    isConvener = false,
    isChecked = false,
    open = false,
    showButton = true,
    isLoading = false,
    onCheck,
    setOpen
}) => {
    return isSearched ?
        <SearchView tournament={tournament} /> :
        <DesktopView tournament={tournament} isAdmin={isAdmin} isConvener={isConvener} isChecked={isChecked} onCheck={onCheck} open={open} setOpen={setOpen} showButton={showButton} isLoading={isLoading}/>
}
export default TournamenListItem;


const SearchView = ({ tournament }) => {
    const [showImage, setShowImage] = useState(false);
    if(!tournament) return;
    const { assets, location, Notes, cost, spots, EntryDeadline } = tournament;
    const deadlinePassed = new Date() > new Date(EntryDeadline);

    return <Grid container spacing={1} sx={{ alignItems: 'center', mb: '1rem', width: '100%' }}>
        <Grid item xs={12} md={4} xl={3} sx={{ textAlign: 'center' }}>
            <EventDate start={tournament.dates[0].start} end={tournament.dates.at(-1).end} />
        </Grid>
        <Grid item xs={12} md={4} xl={3} sx={{ textAlign: 'center' }}>
            <Typography variant='h6' component='h5'>{`${location.diamond}, ${location.city}`}</Typography>
            <Typography sx={{ fontSize: '0.8rem' }} variant='p' component='p'>
                {`Deadline: ${new Date(EntryDeadline).toLocaleDateString('en-us',
                    {
                        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
                    })}`}
            </Typography>
            {!deadlinePassed && spots > 0 && <SpotsLeft tournament={tournament} count={Number(spots)} />}
            {Notes && <Typography sx={{ fontSize: '0.8rem' }}>{`Note: ${Notes}`}</Typography>}
        </Grid>
        <Grid item xs={12} md={4} xl={2} sx={{ textAlign: 'center' }}>
            <Typography variant='h6'><strong>{`$${Number(cost).toFixed(2)}`}</strong></Typography>
        </Grid>
        <Grid item xs={12} xl={4} sx={{ display: 'flex', flexDirection: { xs: 'row', xl: 'column' } }} >
            <RegisterButton key={tournament._id} tournament={tournament} count={spots} />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Button onClick={() => setShowImage(!showImage)} color='success' variant="outlined" sx={{ borderRadius: '50rem', width: '100%', display: 'flex', marginTop: '0.5rem' }}>
                View Poster
            </Button>
            {showImage && <ImgNextGen
                name={assets.poster}
                size={500}
                alt='A poster of the current tournament'
                style={{ borderRadius: '0.5rem', width: '100%', maxWidth: '500px', height: 'auto' }}
            />}
        </Grid>
    </Grid>
}

const DesktopView = ({ tournament, isAdmin, isConvener, isChecked, onCheck, open, setOpen, showButton, isLoading }) => {
    const user = useSelector(state => state.user.account);
    const [showImage, setShowImage] = useState(false);
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.dir === "Left" && eventData.event.isTrusted) {
                onCheck()
            }

        },
        delta: 200, // Minimum distance(px) before a swipe starts. 
    });
    
    if (isLoading || !tournament) return <DesktopSkeleton />

    const isDashboard = isAdmin || isConvener; // these props are only true on the dashboard
    const { assets, location, Notes, cost, spots, EntryDeadline } = tournament;
    const deadlinePassed = new Date() > new Date(EntryDeadline);
    const useAbleHandlers = isDashboard ? handlers : {}

    return <Box {...useAbleHandlers}>
        <Grid container spacing={1} sx={{ alignItems: 'center', mb: '1rem', width: '100%' }}>
            {isDashboard && <Grid item xs={1} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                <Checkbox
                    color="primary"
                    checked={isChecked}
                    onClick={(event) => {
                        if (!event.isTrusted) return;
                        onCheck()
                    }}
                />
            </Grid>}
            {!isDashboard && <Grid item xs={1} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                <ImgNextGen
                    name={assets.poster}
                    size={300}
                    alt='A poster of the current tournament'
                    style={{ borderRadius: '0.5rem', width: '100%', height: 'auto' }}
                />
            </Grid>}
            <Grid item xs={3} md={isDashboard ? 1 : 2} sx={{ textAlign: { xs: 'left', md: 'center' } }}>
                <EventDate start={tournament.dates[0].start} end={tournament.dates.at(-1).end} />
            </Grid>
            <Grid item xs={6} md={4} lg={4} xl={5} sx={{ textAlign: { xs: 'left', md: 'center' } }}>
                <Typography variant='h6' component='h5'>{`${location.diamond}, ${location.city}`}</Typography>
                <Typography sx={{ fontSize: '0.8rem' }} variant='p' component='p'>
                    {`Deadline: ${new Date(EntryDeadline).toLocaleDateString('en-us',
                        {
                            weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
                        })}`}
                </Typography>
                {!deadlinePassed && spots > 0 && <SpotsLeft tournament={tournament} count={Number(spots)} />}
                {Notes && <Typography sx={{ fontSize: '0.8rem' }}>{`Note: ${Notes}`}</Typography>}
                {isDashboard && tournament.tournamentType === "TOC" && <><br /><AutismAwarenessBadge text={"TOC"} /></>}
            </Grid>
            <Grid item xs={3} md={1} lg={2} xl={2} sx={{ textAlign: { xs: 'right', md: 'center' } }}>
                <Typography variant='h6'><strong>{`$${Number(cost).toFixed(2)}`}</strong></Typography>
            </Grid>
            {isDashboard ? user.permissions.dashboard.registrations.write &&
                showButton && <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RegisterButton key={tournament._id} tournament={tournament} count={spots} isDashoard={isDashboard} />
                </Grid>
                :
                showButton && <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RegisterButton key={tournament._id} tournament={tournament} count={spots} isDashoard={isDashboard} />
                </Grid>
            }
            {isDashboard && <Grid item xs={1} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                <IconButton
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </Grid>}
            {isDashboard && <Grid item xs={12} sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'column' }}>
                <Button onClick={() => setOpen(!open)} color='success' variant="outlined" sx={{ borderRadius: '50rem', width: '100%', display: { xs: 'flex', md: 'none' }, marginTop: '0.5rem' }}>
                    Registrations {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Button>
            </Grid>}
            <Grid item xs={12} sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'column' }}>
                <Button onClick={() => setShowImage(!showImage)} color='success' variant="outlined" sx={{ borderRadius: '50rem', width: '100%', display: { xs: 'flex', md: 'none' }, marginTop: '0.5rem' }}>
                    View Poster
                </Button>
                {showImage && <ImgNextGen
                    name={assets.poster}
                    size={500}
                    alt='A poster of the current tournament'
                    style={{ borderRadius: '0.5rem', width: '100%', height: 'auto' }}
                />}
            </Grid>

        </Grid>
    </Box>

}

const DesktopSkeleton = () => {
    return (
        <Box>
            <Grid container spacing={1} sx={{ alignItems: 'center', mb: '1rem', width: '100%' }}>
                <Grid item xs={1} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                    <Skeleton variant="rectangular" sx={{ borderRadius: '0.5rem', width: '100%', height: '250px', margin: 'auto' }} />

                </Grid>
                <Grid item xs={3} md={2} sx={{ textAlign: { xs: 'left', md: 'center' } }}>
                    <Skeleton variant="text" width="60%" height={30} sx={{ margin: 'auto' }} />
                </Grid>
                <Grid item xs={6} md={4} lg={4} xl={5} sx={{ textAlign: { xs: 'left', md: 'center' } }}>
                    <Skeleton variant="text" width="90%" height={30} sx={{ margin: 'auto' }} />
                    <Skeleton variant="text" width="80%" height={20} sx={{ mt: 1, margin: 'auto' }} />
                </Grid>
                <Grid item xs={3} md={1} lg={2} xl={2} sx={{ textAlign: { xs: 'right', md: 'center' } }}>
                    <Skeleton variant="text" width="50%" height={30} sx={{ margin: 'auto' }} />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                    <Skeleton variant="rectangular" width="80%" height={40} sx={{ borderRadius: "25rem", m: '2% 0' }} />
                    <Skeleton variant="rectangular" width="80%" height={40} sx={{ borderRadius: "25rem", m: '2% 0' }} />
                </Grid>
            </Grid>
        </Box>
    );
};