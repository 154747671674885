import React, { useState } from 'react';
import axios from 'axios';
import { OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector } from 'react-redux';

const SearchBar = ({ title, searchRoute, onComplete, onClear, onlycurrent = true, setIsSearching = () => {} }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const token = useSelector(state => state.user.token);
    
    const handleChange = (e) => {
        const newValue = e.target.value;
        setSearchTerm(newValue);

        // Call handleClear if the new value is empty
        if (newValue === '') {
            handleClear();
        }
    };

    const handleSearch = async () => {
        if (searchTerm === '') return handleClear();
        setIsSearching(true);
        try {
            const response = await axios.get(searchRoute, {
                headers: {
                    'Search': searchTerm,
                    'onlycurrent': onlycurrent,
                    'localDate': new Date().toISOString().slice(0, 10),
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.data) throw new Error('No data returned');
            onComplete(response.data);
        } catch (error) {
            console.error('Error during search:', error);
            onComplete([]);
        }
    };

    const handleClear = () => {
        setSearchTerm('');
        onClear();
    };

    return (
        <OutlinedInput
            value={searchTerm}
            onChange={handleChange}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            fullWidth
            placeholder={`Search ${title}...`}
            variant="outlined"
            endAdornment={
                <InputAdornment position="end">
                    {searchTerm && (
                        <IconButton onClick={handleClear}>
                            <ClearIcon />
                        </IconButton>
                    )}
                    <IconButton onClick={handleSearch}>
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            }
            sx={{
                borderRadius: 50
            }}
        />
    );
};

export default SearchBar;
