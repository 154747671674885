import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import { Paper, Backdrop, Box, Button, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import logo from '../../../../assets/Images/logo.webp';
import { useDispatch, useSelector } from 'react-redux';
import formatPhoneNumber from '../../../../shared/lib/formatPhoneNumber';
import { registrationActions } from '../../../../Store/Slices/Registrations/registrationSlice';
import { getRegistrationTotal } from '../../../../shared/lib/getRegistrationTotal';
import RegistrationInvoice from './RegistrationInvoice';
import { formatTimestamp } from '../../../../shared/lib/formatTimestamp';
import { getPayments } from '../../../../shared/Api/getPayments';
import { getTournament, getTournamentById } from '../../../../shared/Api/getTournament';
import { tournamentActions } from '../../../../Store/Slices/Tournaments/tournamentSlice';
import { paymentActions } from '../../../../Store/Slices/Payments/paymentSlice';
import { getConveners, getUsers } from '../../../../shared/Api/getUsers';
import { allUserActions } from '../../../../Store/Slices/Users/userSlice';

const RegistrationReporting = () => {
    const [isLoading, setIsLoading] = useState(true);
    const tournament = useSelector(state => state.tournaments.tournaments.find(tourny => tourny._id === state.registrations.reporting.registrations[0].tournamentId));
    const convener = useSelector(state => state.users.users.find(user => (tournament && user._id === tournament.convenerId)));
    const { registrations, type } = useSelector(state => state.registrations.reporting);
    const dispatch = useDispatch();
    const close = () => dispatch(registrationActions.endReporting()) 
    const paymentArray = useSelector(state => state.payments.payments);
    
    const token = useSelector(state => state.user.token);
    const registrationRef = useRef(registrations[0].tournamentId);
    
    useEffect(() => {
        if (!registrations.length) return;
        Promise.all([
            getTournament(token),
            getPayments(token, [registrations[0].tournamentId]),
            getConveners(token)
        ]).then(async ([tournaments, payments, users]) => {
            if (tournaments) dispatch(tournamentActions.populateArray(tournaments));
            if (users) dispatch(allUserActions.populateArray(users));
            if (!tournaments.some(tourny => tourny._id === registrations[0].tournamentId)) {
                const missingTournament = await getTournamentById(token, registrations[0].tournamentId);
                dispatch(tournamentActions.addTournament(missingTournament));
            }
           
            dispatch(paymentActions.populateArray(payments));
            setIsLoading(false);
        });
    }, [registrations]);

    if (isLoading) return;
    if (type === 'invoice') {
        return <RegistrationInvoice registration={registrations[0]} close={close} />
    }    
    const csvData = [["Team Name", "Captain's Name", "Captain's Cell", "Captain's Email", "Division", "Balance", "Notes"]];
   
    return convener && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
    >
        <Paper sx={{ m: { xs: '1%', md: '10%' }, width: { xs: '98%', md: '80%' }, p: '1rem', overflowY: 'scroll', maxHeight: '95%' }}>
            <Box ref={registrationRef} sx={{ p: '2rem' }}>
                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ marginRight: 'auto' }} variant="h4" component="h4" gutterBottom>{`Download Report`}</Typography>
                </Box>
                <hr />
                <Box key='InvoiceHeader' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <img src={logo} style={{ width: '250px', margin: 'auto', marginBottom: '1rem' }} />
                    <br />
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" gutterBottom component="div">
                            {`${tournament.location.city}, ${tournament.location.diamond} (${new Date(tournament.dates[0].start).toLocaleDateString() || "Date N/A"})`}
                        </Typography>
                        <Typography variant="h6" gutterBottom component="div">
                            {convener.name.givenName} {convener.name.familyName}
                        </Typography>
                    </Box>
                </Box>
                <hr />
                <Box sx={{ margin: '1 0' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="h6" gutterBottom component="div">
                            Registered Teams Report
                        </Typography>
                    </Box>

                    <Table size="normal" aria-label="registration">
                        <TableHead>
                            <TableRow>
                                <TableCell>Team Name</TableCell>
                                <TableCell>Captain's Name</TableCell>
                                <TableCell>Captain's Cell</TableCell>
                                <TableCell>Captain's Email</TableCell>
                                <TableCell>Division</TableCell>
                                <TableCell>Balance</TableCell>
                                <TableCell>Notes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {registrations.map(({ _id, team, OrderTotal }) => {
                                const { balance } = getRegistrationTotal(paymentArray, { _id, OrderTotal })
                                csvData.push([team.name, `${team.captain.firstName} ${team.captain.lastName}`, formatPhoneNumber(team.captain.phone), team.captain.email, team.division, `$${Number(balance).toFixed(2)}`, team.message]);
                                return <TableRow key={_id}>
                                    <TableCell component="th" scope="row">
                                        {team.name || team.team}
                                    </TableCell>
                                    <TableCell>{`${team.captain.firstName} ${team.captain.lastName}`}</TableCell>
                                    <TableCell>{formatPhoneNumber(team.captain.phone)}</TableCell>
                                    <TableCell>{team.captain.email}</TableCell>
                                    <TableCell>{team.division || 'Co-Ed Rec'}</TableCell>
                                    <TableCell>{`$${Number(balance).toFixed(2)}`}</TableCell>
                                    <TableCell>{team.message}</TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Button color='error' onClick={close} variant='outlined' sx={{ borderRadius: '50rem', width: { xs: '98%', md: '48%' }, m: '1%' }}>Cancel</Button>
                {type === 'print' ?
                    <ReactToPrint
                        trigger={() => <Button color='secondary' variant='contained' sx={{ borderRadius: '50rem', width: { xs: '98%', md: '48%' }, m: '1%' }}>{`Print`}</Button>}
                        content={() => registrationRef.current}
                    />
                    : <Button color='success' variant='outlined' sx={{ borderRadius: '50rem', width: { xs: '98%', md: '48%' }, m: '1%' }}><CSVLink filename={`${tournament.location.city}, ${formatTimestamp(tournament.dates[0].start).date} Team Report.csv`} data={csvData}>Download CSV</CSVLink></Button>
                }
            </Box>
        </Paper>
    </Backdrop>
}
export default RegistrationReporting;