import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import logo from '../../../assets/Images/logo.webp';
import { Link } from 'react-router-dom';
import DashboardPages from '../../../data/dashboardPages';
import LoginButton from '../../../shared/components/login/LoginButton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NotificationBell from '../../../shared/components/Badges/NotificationBell';
import { Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Logout from '@mui/icons-material/Logout';
import axios from 'axios';
import { userActions } from '../../../Store/Slices/User/userSlice';
import RegistrationReporting from './Reporting/RegistrationReporting';
import PaymentModel from './PaymentModel';
const widthModile = "300px";
const widthDesktop = "350px";

const drawerWidth = window.innerWidth < 991 ? widthModile : widthDesktop;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: '5rem',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function DashboardWrapper(props) {
    const navigate = useNavigate();
    const { show } = useSelector(state => state.payments.making);
    const reporting = useSelector(state => state.registrations.reporting)
    const user = useSelector(state => state.user.account);
    const token = useSelector(state => state.user.token);
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (!user.permissions.admin && !user.permissions.convener) {
            navigate('/');
        }
    }, [user]);
    const theme = useTheme();
    const [open, setOpen] = React.useState(window.innerWidth < 991 ? false : true);

    const handleDrawerOpen = (event) => {
        if (!event.isTrusted) return;
        setOpen(true);
    };

    const handleDrawerClose = (event) => {
        if (!event.isTrusted) return;
        setOpen(false);
    };

    const logout = () => {
        axios({
            method: "delete",
            url: '/api/user/logout',
            headers: { 'Authorization': `Bearer ${token}` }
        }).then(() => {
            dispatch(userActions.clearAccount);
            dispatch(userActions.clearOrders);
            dispatch(userActions.clearPayments);
            dispatch(userActions.clearRegistrations);
            dispatch(userActions.clearToken);
            dispatch(userActions.logout);
            window.location.replace('/');
        }).catch((error) => {
            alert(error.message);
        });
    }

    if (!user.auth) {
        
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" open={open}>
                <Toolbar sx={{ p: '1rem' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'end' }, width: '100%' }}>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: { xs: 'center', md: 'end' } }}>
                            <LoginButton showName={true} />
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: { xs: 'center', md: 'end' } }}>
                            <NotificationBell />
                        </Box>
                    </Box>

                    {/* TODO search bar here  */}
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: { xs: widthModile, md: widthDesktop },
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: { xs: widthModile, md: widthDesktop },
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader sx={{ display: { xs: "flex", md: "none" } }}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <CloseIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: { xs: "column", md: "row" }, margin: "2%" }}>
                    <img style={{ width: '65px', margin: "3%" }} src={logo} alt='SPFA logo' />
                    <Typography variant='p'>Awareness Athletics Inc.</Typography>
                </Box>
                <Divider />
                <ListItem key={"home"} disablePadding sx={{ display: { xs: "flex", md: "none" } }}>
                    <ListItemButton onClick={() => navigate('/')}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Home"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"profile"} disablePadding sx={{ display: { xs: "flex", md: "none" } }}>
                    <ListItemButton onClick={() => { navigate(`/account`) }}>
                        <ListItemIcon>
                            <ManageAccountsIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Profile"} />
                    </ListItemButton>
                </ListItem>
                <Divider sx={{ display: { xs: "flex", md: "none" } }} />
                {DashboardPages.map((page, index) => {
                    if (!user.permissions.dashboard[page.key]?.read) return;

                    return <ListItem key={page.title} disablePadding>
                        <Link style={{ color: 'inherit', textDecoration: 'inherit', width: '100%' }} to={page.link}>
                            <ListItemButton>
                                <ListItemIcon>
                                    {page.Icon}
                                </ListItemIcon>
                                <ListItemText primary={page.title} />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                })
                }
                {/** Mobile only */}
                <Divider sx={{ display: { xs: "flex", md: "none" } }} />
                <ListItem key={"logout"} disablePadding sx={{ display: { xs: "flex", md: "none" } }}>
                    <ListItemButton onClick={logout}>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primary={"Logout"} />
                    </ListItemButton>
                </ListItem>
            </Drawer>
            <Main open={open}>
                {props.children}
            </Main>
            {reporting.show && <RegistrationReporting />}
            {show && <PaymentModel />}
        </Box>
    );
}
