import React, { Fragment, useState } from 'react';
import { Backdrop, Paper, Skeleton, Typography } from '@mui/material';

const ImgNextGen = ({
  name,
  alt,
  size,
  onLoad,
  style,
  fullscreen,
  onClick
}) => {
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [attempt, setAttempt] = useState(0); // Add a state variable for attempt count
  const [skeletonSize, setSkeletonSize] = useState({ width: '100%', height: 'auto' });

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SkeletonSizeHandler = (width, height) => {
    setSkeletonSize({ width, height });
  };

  const onLoadError = (event) => {
    if (attempt < 5) { // Retry loading up to 5 times
      setAttempt(attempt + 1);
      event.currentTarget.src = `/api/images/${size}/${name}` // Use name on the last attempt
    } else {
      setLoaded(false);
      SkeletonSizeHandler(event.target.width, event.target.height);
    }
  };

  const onLoadSuccess = () => {
    setLoaded(true);
    if (onLoad) onLoad();
  };

  return (
    <Fragment>
      {!loaded && <Skeleton variant="rectangular" width={skeletonSize.width} height={skeletonSize.height} sx={{ aspectRatio: '1/1' }} />}
      <picture>
        <img
          onClick={handleToggle}
          src={`/api/images/${size}/${name}`}
          alt={alt}
          style={style}
          onLoad={onLoadSuccess}
          onError={onLoadError}
        />
      </picture>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        open={open}
        onClick={handleClose}
      >
          <Typography variant='p'>Click to close</Typography>
          <img
            onClick={handleToggle}
            src={`/api/images/${size}/${name}`}
            alt={alt}
            style={{ ...style, cursor: 'pointer', maxHeight: '90vh', maxWidth: '90vw', objectFit: 'contain' }}
            onLoad={onLoadSuccess}
            onError={onLoadError}
          />
      </Backdrop>
    </Fragment>
  );
};

export default ImgNextGen;