import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';

const AddressForm = ({ updateAddress, isDisabled = false, startingAddress = null }) => {
    const [address, setAddress] = useState({
        address1: startingAddress ? startingAddress.address1 : '',
        address2: startingAddress ? startingAddress.address2 : '',
        city: startingAddress ? startingAddress.city : '',
        province: startingAddress ? startingAddress.province : '',
        postal: startingAddress ? startingAddress.postal : '',
        country: startingAddress ? startingAddress.country : '',
    });

    const handleAddressChange = (event) => {
        const { id, value } = event.target;
        setAddress(prevState => ({
            ...prevState,
            [id]: value,
        }));
    };

    useEffect(() => {
        updateAddress(address);
    }, [address]);

    return (
        <>
            <Typography sx={{ width: '100%', margin: "2% 0" }} variant="h5">
                Address
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    {
                        isDisabled ?
                            <TextField fullWidth disabled id="address1" label="Street Address" value={address.address1} onChange={handleAddressChange} /> :
                            <TextField fullWidth id="address1" label="Street Address" value={address.address1} onChange={handleAddressChange} />
                    }
                </Grid>
                <Grid item xs={12} sm={4}>
                    {
                        isDisabled ?
                            <TextField disabled fullWidth id="address2" label="Apt, suite, etc (optional)" value={address.address2} onChange={handleAddressChange} /> :
                            <TextField fullWidth id="address2" label="Apt, suite, etc (optional)" value={address.address2} onChange={handleAddressChange} />
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    {
                        isDisabled ? 
                            <TextField disabled fullWidth id="city" label="City" value={address.city} onChange={handleAddressChange} /> :
                            <TextField fullWidth id="city" label="City" value={address.city} onChange={handleAddressChange} />
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    {
                        isDisabled ?
                            <TextField disabled fullWidth id="province" label="Province/State" value={address.province} onChange={handleAddressChange} /> :
                            <TextField fullWidth id="province" label="Province/State" value={address.province} onChange={handleAddressChange} />
                    }
                </Grid>
                <Grid item xs={12} sm={8}>
                    {
                        isDisabled ?
                            <TextField disabled fullWidth id="country" label="Country" value={address.country} onChange={handleAddressChange} /> :
                            <TextField fullWidth id="country" label="Country" value={address.country} onChange={handleAddressChange} />
                    }
                </Grid>
                <Grid item xs={12} sm={4}>
                    {
                        isDisabled ? 
                            <TextField disabled fullWidth id="postal" label="Postal/ZIP Code" value={address.postal} onChange={handleAddressChange} /> :
                            <TextField fullWidth id="postal" label="Postal/ZIP Code" value={address.postal} onChange={handleAddressChange} />
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default AddressForm;
