import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import WebsiteWrapper from "./shared/WebsiteWrapper";
import DashboardWrapper from "./pages/Dashboard/components/DashboardWrapper";
import NotFound from "./NotFound";

// Lazy-loaded components
const Home = lazy(() => import("./pages/Home/Home"));
const Login = lazy(() => import("./pages/Login/Login"));
const Overview = lazy(() => import("./pages/Dashboard/Overview/Overview"));
const Tournaments = lazy(() => import("./pages/Tournaments/Tournaments"));
const Store = lazy(() => import("./pages/Store/Store"));
const Toc = lazy(() => import("./pages/TOC/Toc"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const RulesInfo = lazy(() => import("./pages/RulesInfo/RulesInfo"));
const Account = lazy(() => import("./pages/Account/Account"));
const PrivacyPolicy = lazy(() => import("./pages/Legal/PrivacyPolicy"));
const TermsCondition = lazy(() => import("./pages/Legal/TermConditions"));
const Refund = lazy(() => import("./pages/Legal/Refund"));
const Cookies = lazy(() => import("./pages/Legal/Cookies"));
const DashTournaments = lazy(() => import("./pages/Dashboard/Tournaments/DashTournaments"));
const DashRegistrations = lazy(() => import("./pages/Dashboard/Registrations/Registrations"));
const SiteUsers = lazy(() => import("./pages/Dashboard/SiteUsers/SiteUser"));
const DashFaq = lazy(() => import("./pages/Dashboard/Faq/DashFaq"));
const DashRulesInfo = lazy(() => import("./pages/Dashboard/RulesInfo/DashRulesInfo"));
const PasswordResetComponent = lazy(() => import("./pages/Login/ResetPassword"));
const Teams = lazy(() => import("./pages/Dashboard/Teams/Teams"));

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <Home />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    // Repeat for each route, using the appropriate wrapper
    {
        path: "/tournaments",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <Tournaments />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    {
        path: "/store",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <Store />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    {
        path: "/tournament-of-champions",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <Toc />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    {
        path: "/about-us",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <AboutUs />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    {
        path: "/rules-info",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <RulesInfo />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    {
        path: "/login",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <Login />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    {
        path: "/user/forgotpassword/:token",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <PasswordResetComponent />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    {
        path: "/account",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <Account />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    // Dashboard
    {
        path: "/dashboard",
        element: (
            <DashboardWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <Overview />
                </Suspense>
            </DashboardWrapper>
        ),
    },
    {
        path: "/dashboard/tournaments",
        element: (
            <DashboardWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <DashTournaments />
                </Suspense>
            </DashboardWrapper>
        ),
    },
    {
        path: "/dashboard/registrations",
        element: (
            <DashboardWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <DashRegistrations />
                </Suspense>
            </DashboardWrapper>
        ),
    },
    {
        path: "/dashboard/teams",
        element: (
            <DashboardWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <Teams />
                </Suspense>
            </DashboardWrapper>
        ),
    },
    {
        path: "/dashboard/users",
        element: (
            <DashboardWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <SiteUsers />
                </Suspense>
            </DashboardWrapper>
        ),
    },
    {
        path: "/dashboard/info",
        element: (
            <DashboardWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <DashRulesInfo />
                </Suspense>
            </DashboardWrapper>
        ),
    },
    {
        path: "/dashboard/faq",
        element: (
            <DashboardWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <DashFaq />
                </Suspense>
            </DashboardWrapper>
        ),
    },
    // Legal
    {
        path: "/privacy-policies",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <PrivacyPolicy />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    {
        path: "/terms-conditions",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <TermsCondition />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    {
        path: "/refunds-returns",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <Refund />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    {
        path: "/cookies",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <Cookies />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
    // 404 not found
    {
        path: "*",
        element: (
            <WebsiteWrapper>
                <Suspense fallback={<div>Loading...</div>}>
                    <NotFound />
                </Suspense>
            </WebsiteWrapper>
        ),
    },
]);

const Routing = (props) => {
    return <RouterProvider router={router} />
}

export default Routing;
