// const axios = require('axios').default;

export const registrationReducers = {
    populateArray(state, { payload }) {
        let registrations = payload || [];
        if (!Array.isArray(registrations)) return;
        registrations = registrations.filter(reg => !(state.registration.some(cachedReg => reg._id === cachedReg._id)))
        state.registration = [
            ...state.registration,
            ...registrations
        ]
    },
    clearArray(state) {
        state.registration = [];
    },
    startRegistration(state, { payload }) {
        const { isRegistering, tournamentId, division, isWaitlist, setIsLoading, isDashoard } = payload;
        state.registering = { isRegistering, tournamentId, division, isWaitlist, setIsLoading, isDashoard };
    },
    endRegistration(state) {
        state.registering = {
            isRegistering: false,
            tournamentId: null,
            division: null,
            isWaitlist: false,
            setIsLoading: null,
            isAdmin: false
        }
    },
    startRoster(state, { payload }) {
        const { tournament, registration, roster, view = false, isDashboard } = payload;
        state.rosterCreation = { tournament, registration, roster, view, isDashboard };
    },
    endRoster(state) {
        state.rosterCreation = {
            tournament: null,
            registration: null,
            roster: null,
            view: false
        }
        window.location.reload()
    },
    startReporting(state, { payload }) {
        const { registrations, show, type } = payload;
        state.reporting = { registrations, show, type };
    },
    endReporting(state) {
        state.reporting = {
            registrations: [],
            show: false,
            type: ""
        };
    },
    startEditingRegistration(state, { payload }) {
        const { registration } = payload;
        state.editRegistration = { 
            registration: registration,
            show: true
        };
    },
    stopEditingRegistration(state) {
        state.editRegistration = {
            registration: {},
            show: false
        };
    }
}