import { Typography } from "@mui/material";

const EventDate = (props) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const offSet = new Date().getTimezoneOffset() * 60000;
    //create local times
    var start = new Date(props.start + offSet);
    var end = new Date(props.end + offSet);
    
    return <Typography variant='h6'>
        {start.toLocaleDateString('en-us') === end.toLocaleDateString('en-us') ?
            `${start.toLocaleDateString('en-us', options)}` : 
            `${start.toLocaleDateString('en-us', options)} - ${end.toLocaleDateString('en-us', options) }`
        }</Typography>
}
export default EventDate;