import {
    Box,
} from "@mui/material";

import logo from '../../assets/Images/logo.webp';
import LoginForm from "./components/LoginForm";

const Login = () => {
    return <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        m: { xs: '2% 1%', md: '5% 15%' },
        width: { xs: '98%', md: '75%' }
    }}>
        <img alt='Slo pitch for awanress logo' src={logo} />
        <br />
        <LoginForm />
    </Box>
}
export default Login;