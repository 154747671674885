import ReactToPrint from 'react-to-print';
import { Paper, Backdrop, Box, Button, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import logo from '../../../../assets/Images/logo.webp';
import { useDispatch, useSelector } from 'react-redux';
import formatPhoneNumber from '../../../../shared/lib/formatPhoneNumber';
import { getRegistrationTotal } from '../../../../shared/lib/getRegistrationTotal';

const RegistrationInvoice = ({ registration, close }) => {
    const { payments, tournament } = useSelector(state => ({
        payments: state.payments.payments.filter(payment => registration._id === payment.Invoice),
        tournament: state.tournaments.tournaments.find(tourny => tourny._id === registration?.tournamentId)
    }));
    const registrationRef = useRef(registration._id);
    const { balance, paid } = getRegistrationTotal(payments, registration)

    const registrationDate = new Date(registration.date).toDateString()
    const registrationTime = new Date(registration.date).toLocaleTimeString()

    return tournament && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
    >
        <Paper sx={{ m: { xs: '1%', md: '10%' }, width: { xs: '98%', md: '80%' }, p: '1rem', overflowY: 'scroll', maxHeight: '95%' }}>
            <Box ref={registrationRef} sx={{ p: '2rem' }}>
                <Box key='InvoiceHeader' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <img src={logo} style={{ width: '250px', margin: 'auto', marginBottom: '1rem' }} />
                    <Typography sx={{ margin: 'auto' }} variant="h5" component="h5" gutterBottom>{`Invoice #${registration._id}`}</Typography>
                    <hr />
                </Box>
                <Box key='HeaderDetails' sx={{ display: 'flex' }}>
                    <Box sx={{ textAlign: 'left', marginRight: 'auto' }}>
                        <Typography variant="p" component="p" gutterBottom>{`Date: ${registrationDate}`}</Typography>
                        <Typography variant="p" component="p" gutterBottom>{`Time: ${registrationTime}`}</Typography>
                        <Typography variant="h6" component="h6" gutterBottom>{`Team's Name: ${registration.team.name || registration.team.team}`}</Typography>
                        <Typography variant="p" component="p" gutterBottom>{`Captain's Name: ${registration.team.captain.firstName} ${registration.team.captain.lastName}`}</Typography>
                        <Typography variant="p" component="p" gutterBottom>{`Captain's Cell: ${formatPhoneNumber(registration.team.captain.phone)}`}</Typography>
                        <Typography variant="p" component="p" gutterBottom>{`Captain's Email: ${registration.team.captain.email || ''}`}</Typography>
                    </Box>
                    <Box sx={{ textAlign: 'right', marginLeft: 'auto' }}>
                        <Typography variant="p" component="p" gutterBottom>{`Awareness Athletics Inc.`}</Typography>
                        <Typography variant="p" component="p" gutterBottom>{`HST #: 717849715RT0001`}</Typography>
                        <Typography variant="p" component="p" gutterBottom>{`19 Cottonwood Drive,`}</Typography>
                        <Typography variant="p" component="p" gutterBottom>{`Belleville, ON Canada,`}</Typography>
                        <Typography variant="p" component="p" gutterBottom>{`K8N 0J3`}</Typography>
                        <Typography variant="p" component="p" gutterBottom>{`Info@spfacanada.ca`}</Typography>
                    </Box>
                </Box>
                <hr />
                <Box sx={{ margin: '1 0' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="h6" gutterBottom component="div">
                            Registered Tournament Details
                        </Typography>
                    </Box>

                    <Table size="normal" aria-label="registrations">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>City</TableCell>
                                <TableCell>Diamond</TableCell>
                                <TableCell>Division</TableCell>
                                <TableCell>Registration Cost</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {tournament.dates ? new Date(tournament.dates[0].start).toLocaleDateString() : 'N/A'}
                                </TableCell>
                                <TableCell>{tournament.location.city}</TableCell>
                                <TableCell>{tournament.location.diamond}</TableCell>
                                <TableCell>{registration.team.division || 'Co-Ed'}</TableCell>
                                <TableCell>{`$${Number(tournament.cost).toFixed(2)}`}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                <br />
                <Box sx={{ display: 'flex', justifyContent: 'end', alignText: 'right', flexDirection: 'column' }}>
                    <Typography sx={{ marginLeft: 'auto' }} variant="p" component="p" gutterBottom>{`Subtotal: $${Number(tournament.cost).toFixed(2)}`}</Typography>
                    <Typography sx={{ marginLeft: 'auto' }} variant="p" component="p" gutterBottom>{`Tax: ( inclusive )`}</Typography>
                    <Typography sx={{ marginLeft: 'auto' }} variant="p" component="p" gutterBottom>{`Total: $${Number(registration.OrderTotal).toFixed(2)}`}</Typography>
                    <Typography sx={{ marginLeft: 'auto' }} variant="p" component="p" gutterBottom>{`Paid: $${Number(paid).toFixed(2)}`}</Typography>
                    <Typography sx={{ marginLeft: 'auto' }} variant="p" component="p" gutterBottom>{`Balance: $${Number(balance).toFixed(2)}`}</Typography>
                </Box>
                <Box sx={{ margin: '1 0' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="h6" gutterBottom component="div">
                            Payments
                        </Typography>
                    </Box>

                    <Table size="normal" aria-label="registrations">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Currency</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payments.map((payment, index) => {
                                return <TableRow key={`${index}-${payment._id}`}>
                                    <TableCell component="th" scope="row">
                                        {payment.date}
                                    </TableCell>
                                    <TableCell>{payment.type}</TableCell>
                                    <TableCell>{`$${Number(payment.amount).toFixed(2)}`}</TableCell>
                                    <TableCell>{payment.currency}</TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Button color='error' onClick={close} variant='outlined' sx={{ borderRadius: '50rem', width: { xs: '98%', md: '48%' }, m: '1%' }}>Cancel</Button>
                <ReactToPrint
                    trigger={() => <Button color='secondary' variant='contained' sx={{ borderRadius: '50rem', width: { xs: '98%', md: '48%' }, m: '1%' }}>Print</Button>}
                    content={() => registrationRef.current}
                />
            </Box>
        </Paper>
    </Backdrop>
}
export default RegistrationInvoice;