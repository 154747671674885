import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { reduxStore, ReduxStoreStartup } from './Store';
import App from './App';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

//TODO: make this env variables and NOT static strings
const initialOptions = {
  "client-id": process.env.NODE_ENV === 'development' ? 'AU5x1CmyyI8AFq7JssxT4UpmDjj1T-SNzhsGkEUs0bpDl89lD3T0YpbzOsPSApY33U-Z1QoTjIpLR7Xm' : 'Aaw7unfKIH7zTVhX42PmqrmxkVXITXeQeBTqE3yZaed4ziMwTC-IY_IdMsfug39gUTic8JqC7hjPdHmo',
  currency: "CAD",
  intent: "capture",
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PayPalScriptProvider deferLoading={true} options={initialOptions}>
      <Provider store={reduxStore}>
        <ReduxStoreStartup />
        <App />
      </Provider>
    </PayPalScriptProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
