import { Button, Paper, Typography } from "@mui/material";

export const CheckState = ({ setStage, isWaitlist }) => {
    return <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
        <Typography variant='h6'>Please Note</Typography>
        {isWaitlist ?
            <p>If you add your team to the waitlist you will be noticed to register IF a spot becomes available</p> :
            <p>All new teams are required to pay a $100.00 deposit before their registration will be complete</p>
        }
        <Button onClick={() => { setStage('name') }} sx={{ borderRadius: '50rem', width: '98%', m: '1%' }} variant="contained" color="success">I Understand</Button>
    </Paper>
}