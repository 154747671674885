// const axios = require('axios').default;

export const tournamentReducers = {
    populateArray(state, action) {
        state.tournaments = action.payload;
    },
    clearArray(state) {
        state.tournaments = [];
    },
    addDivisions(state, action) {
        state.divisions = action.payload;
    },
    clearDivisions(state) {
        state.divisions = [];
    },
    startEditing(state, { payload }) {
        const { tournament } = payload;
        state.details = { tournament, show: true };
    },
    endEditing(state) {
        state.details = {
            show: false,
            tournament: null
        }
    },
    addTeams(state, action) {
        state.teams = action.payload;
    },
    clearTeams(state) {
        state.teams = [];
    },
    addTournament(state, { payload }) {
        state.tournaments = [
            ...state.tournaments,
            payload
        ]
    }
}