export const formatTimestamp = (timestamp, withYear = true) => {
    // Create a Date object from the timestamp
    const dateObj = new Date(timestamp);

    // Array of month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    // Get the month name
    const month = months[dateObj.getMonth()];

    // Get the day and determine the correct ordinal suffix
    const day = dateObj.getDate();
    const ordinal = (day % 10 === 1 && day !== 11) ? 'st' : (day % 10 === 2 && day !== 12) ? 'nd' : (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

    // Format the date as "MonthName DayOrdinal, Year"
    const date = withYear ? `${month} ${day}${ordinal}, ${dateObj.getFullYear()}` : `${month} ${day}${ordinal}`;

    // Format the time as "HH:MM AM/PM"
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 24h to 12h format and handle midnight case
    const time = `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;

    // Return the formatted date and time as an object
    return { date, time };
} 