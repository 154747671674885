import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import formatPhoneNumber from '../../lib/formatPhoneNumber';

const CaptainsForm = ({ updateTeamDetails, details, children }) => {
    const [captainDetails, setCaptainDetails] = useState({
        firstName: details ? details.firstName : "",
        lastName: details ? details.lastName : "",
        phone: details ? details.phone : "",
        email: details ? details.email : ""
    });

    useEffect(() => setCaptainDetails(details), [details])

    const handleDetailChange = (event) => {
        const { id, value } = event.target;

        setCaptainDetails(prevState => ({
            ...prevState,
            [id]: id === "phone" ? formatPhoneNumber(value) : value,
        }));
    };

    return (
        <>
            <Typography sx={{ width: '100%', marginBottom: "10px" }} align="start" variant="h5">
                Please enter the captains details
            </Typography>
            {children}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth id="firstName" label="First Name" value={captainDetails.firstName} onChange={handleDetailChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth id="lastName" label="Last Name" value={captainDetails.lastName} onChange={handleDetailChange} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth id="phone" label="Phone #" value={captainDetails.phone} onChange={handleDetailChange} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth id="email" label="Email Address" value={captainDetails.email} onChange={handleDetailChange} />
                </Grid>
            </Grid>
        </>
    );
}

export default CaptainsForm;
