import axios from "axios";

export const getPayments = async (token, tournamentIds) => {
    try {
        const res = await axios({
            method: "post",
            url: '/api/payments/all',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                tournamentIds: tournamentIds
            }
        })
        return res.data;
    } catch (err) {
        console.error(`Failed to get payments`, err);
        return null;
    }
} 