import { createSlice } from '@reduxjs/toolkit';
import { rosterReducers } from './rostersReducers';

const rosterInit = {
    rosters: [],
}

export const rosterSlice = createSlice({
    name: 'rosters',
    initialState: rosterInit,
    reducers: rosterReducers
});
export const rosterActions = rosterSlice.actions;