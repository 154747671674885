import { createSlice } from '@reduxjs/toolkit';
import { metricsReducers } from './metricsReducers';

const metricsInit = {
    registrationTotal: null,
    registrationGrowth: null,
    SiteUserGrowth: null,
    TeamGrowth: null
}

export const metricsSlice = createSlice({
    name: 'metrics',
    initialState: metricsInit,
    reducers: metricsReducers
});
export const metricsActions = metricsSlice.actions;