import { Button, Paper, Box, Grid } from "@mui/material";
import SearchBar from "../../../SearchBar";
import CaptainsForm from "../../CaptainsForm";
import { useState } from "react";
import formatPhoneNumber from "../../../../lib/formatPhoneNumber";

export const CaptainDetailsStage = ({ captain, onUpdate, onComplete, onClose }) => {
    const [searchedPlayers, setSearchedPlayers] = useState([]);
    const onSearchCompleted = (players) => setSearchedPlayers(players);

    const PlayerList = ({ player, onUpdate }) => {
        const updateTeamDetails = (player) => {
            onUpdate({
                firstName: player.name.givenName,
                lastName: player.name.familyName,
                phone: formatPhoneNumber(player.phone),
                email: player.username
            });
            setSearchedPlayers([])
        }

        return <Button onClick={() => updateTeamDetails(player)} color="inherit" sx={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'start' }}>
                    {player.name.givenName} {player.name.familyName}
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'start' }}>
                    {player.phone}
                </Grid>
                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                    {player.username}
                </Grid>
            </Grid>
        </Button>
    };

    return <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
        <CaptainsForm updateTeamDetails={onUpdate} details={captain}>
            <SearchBar title={"Captains"} searchRoute={"/api/user/all"} onComplete={onSearchCompleted} onClear={() => setSearchedPlayers([])} />
            <Box sx={{ padding: '2.5px 2%', maxHeight: "25vh", overflowY: "scroll" }}>
                {searchedPlayers.map(player => <PlayerList player={player} onUpdate={onUpdate} />)}
            </Box>
            <hr />
        </CaptainsForm>
        <Box sx={{ display: 'flex', p: '1rem 0 0 0' }}>
            <Button onClick={onClose} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="error">Cancel</Button>
            <Button onClick={onComplete} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="success">Confirm Details</Button>
        </Box>
    </Paper>
}