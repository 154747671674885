import { Fragment, useEffect, useState } from "react";
import { Button, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CircularProgress from '@mui/material/CircularProgress';
import PaypalButton from "../../Buttons/paypalButton";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Alert from '@mui/material/Alert';
import { Backdrop } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { registrationActions } from "../../../../Store/Slices/Registrations/registrationSlice";
import { CheckState } from "./States/CheckState";
import { CaptainDetailsStage } from "./States/CaptainDetailsStage";
import trimPhoneNumber from "../../../lib/trimPhoneNumber";

//TODO: break apart into different components!
const RegistrationArea = ({ tournament, division, close, isWaitlist, isDashoard = false }) => {
    const user = useSelector(state => state.user.account);
    const userAuth = useSelector(state => state.user.auth);
    const token = useSelector(state => state.user.token);
    const dispatch = useDispatch();
    const [registration, setRegistration] = useState(null);
    const [stage, setStage] = useState(isDashoard ? "captainDetails" : !userAuth ? "login?" : "loading");
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [responses, setResponse] = useState('');
    const [data, setData] = useState({
        tournamentId: tournament._id,
        name: "",
        captain: {
            firstName: isDashoard ? "" : user.name.givenName,
            lastName: isDashoard ? "" : user.name.familyName,
            phone: isDashoard ? "" : user.phone,
            email: isDashoard ? "" : user.username
        },
        status: null,
        comments: '',
        division: division,
        payment: null
    });

    const Axios = axios.create({
        headers: { 'Authorization': `Bearer ${token}` }
    });

    useEffect(() => {
        if (!userAuth) return;
        if (!isDashoard) {
            checkTeamStatus(data.captain)
        }
    }, []);

    const updateTeamDetails = (captain) => {
        setData(prevs => ({
            ...prevs,
            captain
        }))
    }
    const confirmCaptainDetails = () => {
        checkDivision(() => {
            setStage('loading');
            checkTeamStatus(data.captain);
        }, false);
    }
    const checkDivision = (cd, isStage = true) => {
        Axios.post('/api/tournament/register/check', {
            tournamentId: data.tournamentId,
            team: {
                captain: data.captain,
                phone: data.phone,
                division: data.division
            },
        }).then((response) => {
            if (response.data) {
                setResponse({ responseText: "Your team as already registered for this event(and/or division)", status: 403 });
                setStage('error');
                return
            }
            if (isStage) {
                setStage(cd);
            } else {
                cd();
            }

        }).catch((err) => {
            if (err.response.status === 401) {
                return alert("Login please")
            }
            setResponse({ responseText: "Your team as already registered for this event(and/or division)", status: 400 });
            setStage('error')
        })
    }

    const checkTeamStatus = (captain) => {
        Axios.post(`/api/tournament/register/status`, {
            captain
        }).then((response) => {
            const status = response.data;
            setData((prevs) => ({
                ...prevs,
                status: status
            }));
            setStage("check")
        });
    }
    const changedName = (event) => {
        var name = event.target.value;
        setData((prevs) => ({
            ...prevs,
            name: name
        }));
    }
    const enterName = () => {
        if (data.name.trim().length === 0) {
            setError('Please Fill in a team name');
            return;
        } else {
            setError(false);
        }
        checkDivision('comments');
    }
    const addComments = (event) => {
        var comment = event.target.value;
        setData((prevs) => ({
            ...prevs,
            comments: comment
        }));
    }
    const onAddComment = () => {
        if (!isWaitlist && !isDashoard) {
            return setStage('paymentCheck');
        }
        registerNow().then((data) => {
            setResponse(isWaitlist ? `Successfully add to waitlist! Waitlist number ${data.id}` : `Successfully registered! Invoice number ${data.id}`);
            setStage('completed');
        }).catch((data) => {
            setResponse({ responseText: isWaitlist ? `Waitlist failed will error, ${data}` : `Registration failed will error, ${data}`, status: 500 });
            setStage('error');
        });
    }
    const registerNow = () => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            Axios.post('/api/tournament/register', {
                "tournamentId": data.tournamentId,
                "isWaitlisted": isWaitlist,
                "isDashoard": isDashoard,
                "team": {
                    "name": data.name,
                    "captain": {
                        "firstName": data.captain.firstName.trim(),
                        "lastName": data.captain.lastName.trim(),
                        "phone": trimPhoneNumber(data.captain.phone),
                        "email": data.captain.email
                    },
                    "status": data.status,
                    "message": data.comments,
                    "division": data.division
                },
                "roasterId": null,
                "orderTotal": tournament.cost,
                "payment": data.payment
            }).then((response) => {
                setRegistration(response.data.registration);
                resolve(response.data);
            }).catch((error) => {
                reject(error.message);
            }).finally(() => {
                setIsLoading(false);
            })
        });
    }
    const PayLater = () => {
        registerNow().then((data) => {
            setResponse(`Registration Successful! Registration number ${data.id}`);
            setStage('completed');
        }).catch((data) => {
            setResponse({ responseText: `Registration failed will error, ${data}`, status: 500 });
            setStage('error');
        });
    }
    const payment = (payment) => {
        setData((prevs) => ({
            ...prevs,
            payment: payment
        }));
        registerNow().then((data) => {
            setResponse(`Registration Successful! Registration number ${data.id}`);
            setStage('paymentCompleted');
        }).catch((data) => {
            setResponse({ responseText: `Registration failed will error, ${data}`, status: 500 });
            setStage('error');
        });
    }
    const emt = () => {
        registerNow().then((data) => {
            setResponse(`Registration Successful! Registration number ${data.id}`);
            setStage('emt');
        }).catch((data) => {
            setResponse({ responseText: `Registration failed will error, ${data}`, status: 500 });
            setStage('error');
        });
    }

    const createRoster = () => {
        dispatch(registrationActions.startRoster({
            registration,
            tournament,
            roster: null,
        }));
        close();
    }

    return <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
    >
        {stage === 'loading' && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
            <CircularProgress color="secondary" />
        </Paper>}
        {stage === 'check' && data.status === 'new' && <CheckState setStage={setStage} isWaitlist={isWaitlist} />}
        {stage === 'check' && data.status !== 'new' && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
            <Typography variant='h5'>Teams Name</Typography>
            <TextField fullWidth value={data.name} onChange={changedName} id='TeamName' label="Please Enter A Team Name" />
            <Box sx={{ display: 'flex', p: '1rem 0 0 0' }}>
                <Button onClick={close} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="error">Cancel</Button>
                <Button onClick={enterName} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant={error ? 'outlined' : "contained"} color={error ? 'error' : 'success'}>Next</Button>
            </Box>
            {error && <Alert severity="error">{error}</Alert>}
        </Paper>
        }
        {stage === "name" && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
            <Typography variant='h5'>Teams Name</Typography>
            <TextField fullWidth value={data.name} onChange={changedName} id='TeamName' label="Please Enter A Team Name" />
            <Box sx={{ display: 'flex', p: '1rem 0 0 0' }}>
                <Button onClick={close} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="error">Cancel</Button>
                <Button onClick={enterName} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant={error ? 'outlined' : "contained"} color={error ? 'error' : 'success'}>Next</Button>
            </Box>
            {error && <Alert severity="error">{error}</Alert>}
        </Paper>}
        {/* logging in to register  */}
        {stage === "login?" && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
            <Typography variant='h5'>Please Login to register</Typography>
            <Box sx={{ display: 'flex', p: '1rem 0 0 0' }}>
                <Button onClick={() => { window.location.replace('/login') }} sx={{ borderRadius: '50rem', width: '98%', m: '1%' }} variant="contained" color="success">Login now</Button>
            </Box>
        </Paper>}
        {stage === "comments" && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
            <Typography variant='h5'>Comments</Typography>
            <TextField fullWidth onChange={addComments} id='comments' label="Notes and comments" />
            <Box sx={{ display: 'flex', p: '1rem 0 0 0' }}>
                <Button onClick={close} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="error">Cancel</Button>
                <Button onClick={onAddComment} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="primary">Next</Button>
            </Box>
        </Paper>}
        {stage === "paymentCheck" && <Fragment>
            {data.status === "new" && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
                <Typography variant='h5'>Payment options</Typography>
                <Box sx={{ display: 'flex', p: '1rem 0 0 0' }}>
                    <Button onClick={() => { setStage('fullPayment'); }} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="primary">Full Payment</Button>
                    <Button onClick={() => { setStage('deposit'); }} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="success">Deposit</Button>
                </Box>
            </Paper>}
            {data.status === "Good" && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
                <Typography variant='h5'>Confirmation</Typography>
                <Box sx={{ display: 'flex' }}>
                    {isLoading ?
                        <Button disabled sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="success">
                            <CircularProgress size={30} />
                        </Button> :
                        <Button onClick={PayLater} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="success">Pay Later</Button>
                    }
                    <Button onClick={() => { setStage('showPaymentOptions'); }} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="primary">Pay Now</Button>
                </Box>
            </Paper>}
            {data.status === "Prepay" && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
                <Typography variant='h5'>Payment Methods</Typography>
                <Box sx={{ display: 'flex', alignItems: 'start', p: '1rem 0 0 0' }}>
                    {isLoading ?
                        <Button disabled sx={{ borderRadius: '50rem', width: '50%', m: '0 1%', height: 45 }} variant="contained" color="primary">
                            <CircularProgress size={30} />
                        </Button> :
                        <Button onClick={emt} sx={{ borderRadius: '50rem', width: '50%', m: '0 1%', height: 45 }} variant="contained" color="primary">{`E.M.T. (${tournament.cost})`}</Button>
                    }

                    <PaypalButton amount={tournament.cost} onComplete={payment} />
                </Box>
            </Paper>}
            {data.status === "Depoist" && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
                <Typography variant='h5'>Confirmation & Payment</Typography>
                <Box sx={{ display: 'flex' }}>
                    <Button onClick={() => { setStage('fullPayment'); }} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="primary">Full Payment</Button>
                    <Button onClick={() => { setStage('deposit'); }} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="success">Deposit</Button>
                </Box>
            </Paper>}
        </Fragment>}
        {stage === "showPaymentOptions" && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
            <Typography variant='h5'>Payment options</Typography>
            <Box sx={{ display: 'flex', p: '1rem 0 0 0' }}>
                <Button onClick={() => { setStage('fullPayment'); }} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="primary">Full Payment</Button>
                <Button onClick={() => { setStage('deposit'); }} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="success">Deposit</Button>
            </Box>
        </Paper>}
        {stage === 'fullPayment' && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
            <Typography variant='h5'>Payment Methods</Typography>
            <Box sx={{ display: 'flex', alignItems: 'start', p: '1rem 0 0 0' }}>
                {isLoading ?
                    <Button disabled sx={{ borderRadius: '50rem', width: '50%', m: '0 1%', height: 45 }} variant="contained" color="primary">
                        <CircularProgress size={30} />
                    </Button> :
                    <Button onClick={emt} sx={{ borderRadius: '50rem', width: '50%', m: '0 1%', height: 45 }} variant="contained" color="primary">{`E.M.T. (${tournament.cost})`}</Button>
                }
                <PaypalButton isLoading={isLoading} amount={tournament.cost} onComplete={payment} />
            </Box>
        </Paper>}
        {stage === 'deposit' && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
            <Typography variant='h5'>Payment Methods</Typography>
            <Box sx={{ display: 'flex', alignItems: 'start', p: '1rem 0 0 0' }}>
                {isLoading ?
                    <Button disabled sx={{ borderRadius: '50rem', width: '50%', m: '0 1%', height: 45 }} variant="contained" color="primary">
                        <CircularProgress size={30} />
                    </Button> :
                    <Button onClick={emt} sx={{ borderRadius: '50rem', width: '50%', m: '0 1%', height: 45 }} variant="contained" color="primary">{`E.M.T. ($100.00))`}</Button>
                }
                <PaypalButton isLoading={isLoading} amount={100} onComplete={payment} />
            </Box>
        </Paper>}
        {stage === 'emt' && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
            <CheckCircleOutlineIcon color='success' sx={{ fontSize: 55 }} />
            <Typography variant='h5'>Registration Complete!</Typography>
            <hr />
            <p><strong>E.M.T. Instructions</strong></p>
            <p>Email: <span style={{ color: 'red', fontWeight: '700' }}>info@spfacanada.ca</span></p>
            <p>Password: <span style={{ color: 'red', fontWeight: '700' }}>softball</span></p>
            <hr />
            <p>{responses}</p>
            <Button onClick={() => { window.location.reload() }} sx={{ borderRadius: '50rem', width: '98%', m: '1%' }} variant="contained" color="success">Close</Button>
        </Paper>}
        {stage === 'completed' && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
            <CheckCircleOutlineIcon color='success' sx={{ fontSize: 55 }} />
            <Typography variant='h5'>{isWaitlist ? "Added to Waitlist" : "Registration Complete!"}</Typography>
            {!isWaitlist && <Typography variant="p">Please note: Registrations are not considered complete until you have paid deposit (if necessary) and submitted your roster. Rosters may be edited later.</Typography>}
            <p>{responses}</p>
            <Box sx={{ display: 'flex', p: '1rem 0 0 0' }}>
                <Button onClick={createRoster} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color='success'>Create Roster</Button>
                <Button onClick={() => { window.location.reload() }} sx={{ borderRadius: '50rem', width: '50%', m: '1%' }} variant="contained" color="error">Close</Button>
            </Box>

        </Paper>}
        {stage === 'error' && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
            <ErrorOutlineIcon color="error" sx={{ fontSize: 55 }} />
            <Typography variant='h5'>There seems to be an issue</Typography>
            <p>{responses.responseText}</p>
            <Button onClick={close} sx={{ borderRadius: '50rem', width: '98%', m: '1%' }} variant="contained" color="error">Cancel</Button>
        </Paper>}
        {stage === 'paymentCompleted' && <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', zIndex: '6000' }}>
            <CheckCircleOutlineIcon color='success' sx={{ fontSize: 55 }} />
            <Typography variant='h5'>Registered & Paid!</Typography>
            <p>{responses}</p>
            <Button onClick={() => { window.location.reload() }} sx={{ borderRadius: '50rem', width: '98%', m: '1%' }} variant="contained" color="success">Close</Button>
        </Paper>}
        {stage === 'captainDetails' && <CaptainDetailsStage captain={data.captain} onUpdate={updateTeamDetails} onClose={close} onComplete={confirmCaptainDetails} />}
    </Backdrop>
}
export default RegistrationArea;