const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, ''); // Remove all non-digit characters
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/); // Match the phone number components

    if (match) {
        // Check if the country code is present
        var intlCode = (match[1] ? '+1 ' : '');
        // Format the number
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return phoneNumberString; // Return the original string if it doesn't match the expected format
};

export default formatPhoneNumber;